<script setup lang="ts">
import { COOKIE_KEYS, DEFAULT_HOURS_FORMAT } from "@/utils/constants";
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { useDate } from "@/composables/useDate";

const { $get, $post } = useAxios();
const route = useRoute();

const props = defineProps({
  // isFormOpen: {
  //   type: Boolean,
  //   default: false,
  // },
  typeButton: {
    type: String,
    default: "primary", //'primary, secondary, third'
  },
});

// const emit = defineEmits(["update:isFormOpen"]);

const isFormOpen = computed({
  get: () => {
    return route.query?.isFormOpen === "true";
  },
  set: async (value: boolean) => {
    await navigateTo(`${route.path}?isFormOpen=${value}`);
  },
});

const message = ref("");
const phone = ref("");
const isSending = ref(false);
const theme = ref(null);
const chat = ref<HTMLDivElement | null>(null);
const scrollArea = ref<HTMLDivElement | null>(null);

const ticketId = useCookie(COOKIE_KEYS.CHAT_TICKET_ID, {
  maxAge: 60 * 60 * 24 * 2,
});
const clientId = useCookie(COOKIE_KEYS.CHAT_CLIENT_ID, {
  maxAge: 60 * 60 * 24 * 2,
});

const messages = ref<
  {
    ticketId: number | string;
    datetime: string;
    msgBody: string;
    isClientMsg: boolean;
  }[]
>([]);

const { formatDate } = useDate();

async function refreshMessages() {
  try {
    if (ticketId.value) {
      const result = await $post<
        Awaited<
          ReturnType<typeof import("@/server/api/v1/update.post").default>
        >
      >("https://amra-bank.com/api/v1/update", { ticketId: ticketId.value });

      messages.value = result.data as {
        ticketId: number | string;
        datetime: string;
        msgBody: string;
        isClientMsg: boolean;
      }[];
    }
    return [];
  } catch (error) {
    console.error(error);
  }
}

watch(
  messages,
  () => {
    const message = messages.value.find((message) =>
      messages.value.at(-1).msgBody === "exit" ? message : undefined
    );
    if (message) {
      ticketId.value = "";
      isFormOpen.value = false;
      messages.value = [];
      setTimeout(refreshMessages, 0);
    }
  },
  {
    immediate: true,
  }
);

const createTicketRules = {
  theme: {
    required,
  },
  phone: {
    required,
  },
};

const createMessageRules = {
  message: {
    required,
    minLength: minLength(1),
  },
};
const createTicketValidator = useValidate(createTicketRules, { phone, theme });
const createMessageValidator = useValidate(createMessageRules, { message });

const { data: themes } = await useAsyncData(
  "get all themes",
  () => $fetch("https://amra-bank.com/api/v1/ticket-themes"),
  { default: () => [], lazy: true }
);
const { data: operators } = await useAsyncData<number>(
  "get operators online",
  () => $fetch("https://amra-bank.com/api/v1/operators-online"),
  { default: () => 0, lazy: true }
);

function toggleForm() {
  isFormOpen.value = !isFormOpen.value;
}

watch([theme, phone, message], () => {
  createMessageValidator.value.$reset();
  createTicketValidator.value.$reset();
});

watch(isFormOpen, () => {
  chat.value?.focus();
});

async function scroll() {
  const scrollArea1 = document.getElementById("scrollArea");
  if (scrollArea1) {
    var height = scrollArea1.getBoundingClientRect().height;
    var top = scrollArea1.getBoundingClientRect().top;
    var bottom = height - top;
    console.log(bottom);
    scrollArea1.scrollIntoView({ block: "end" });
  }
}

async function sendMessage(e: any) {
  e.preventDefault();
  if (isSending.value) return;
  isSending.value = true;
  try {
    if (ticketId.value && clientId.value) {
      await createMessageValidator.value.$validate();
      if (createMessageValidator.value.$errors.length > 0)
        throw new Error("Пожалуйста, заполните текст сообщения");
      const result = await $post<
        Awaited<
          ReturnType<
            typeof import("@/server/api/v1/authorityMessage.post").default
          >
        >
      >("https://amra-bank.com/api/v1/authorityMessage", {
        ticketId: ticketId.value,
        clientId: clientId.value,
        message: message.value,
      });

      if (result.statusCode === 201) {
        message.value = "";
      }

      if (result.statusCode === 500) alert(result.message);
    } else {
      await createTicketValidator.value.$validate();
      await createMessageValidator.value.$validate();
      if (createTicketValidator.value.$errors.length > 0)
        throw new Error("Пожалуйста, заполните все поля");
      if (createMessageValidator.value.$errors.length > 0)
        throw new Error("Пожалуйста, заполните текст сообщения");

      const result = await $post<
        Awaited<
          ReturnType<typeof import("@/server/api/v1/message.post").default>
        >
      >("https://amra-bank.com/api/v1/message", {
        theme: theme.value,
        phone: phone.value,
        message: message.value,
      });

      if (result.statusCode === 201) {
        message.value = "";
        ticketId.value = result.data.ticketId;
        clientId.value = result.data.clientId;
      }

      if (result.statusCode === 500) alert(result.message);
    }

    await refreshMessages();
  } catch (error: any) {
    if (error?.isAxios) {
      console.error(error?.response?.data);
      alert("Что-то пошло не так. Пожалуйста, попробуйте позже.");
    } else {
      alert(error.message);
    }
  } finally {
    isSending.value = false;
    scrollArea.value?.scroll({ left: 0, top: 100500 });
  }
  scroll();
}

setTimeout(refreshMessages, 0);

let interval

onMounted(() => {
 interval = setInterval(refreshMessages, 1000); // Start the interval after the component is mounted
});

onBeforeUnmount(() => clearInterval(interval));

function operatorsOnline(n: number) {
  if (n > 0) return `Операторы онлайн`;
  return ``;
}

function closeChat() {
  isFormOpen.value = false;
}

onMounted(() => {
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      closeChat();
    }
  });
  if (route.query.isFormOpen == "true") {
    isFormOpen.value = true;
  }
});

const generateChatText = () => {
  let chatText = "";
  for (const message of messages.value) {
    const sender = message.isClientMsg ? "Client" : "Operator";
    const datetime = formatDate(message.datetime, DEFAULT_HOURS_FORMAT);
    chatText += `[${datetime}] ${sender}: ${message.msgBody}\n`;
  }
  return chatText;
};

const downloadChat = () => {
  const chatText = generateChatText();
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), chatText], {
    type: "text/plain;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "chat_dialog.txt";
  link.click();
  URL.revokeObjectURL(url);
};

var textareaChat = ref(null);

onMounted(() => {
  resizeTextarea();
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeTextarea);
});

const resizeTextarea = () => {
  if (textareaChat.value) {
    textareaChat.value.style.height = "auto";
    textareaChat.value.style.height = `${textareaChat.value.scrollHeight}px`;

    if (message.value) {
      if (textareaChat.value.scrollHeight > 102) {
        textareaChat.value.style.height = "102px !important";
      } else if (textareaChat.value.scrollHeight < 46) {
        textareaChat.value.style.height = "46px !important";
      } else {
        textareaChat.value.style.height = "46px !important";
      }
    } else {
      textareaChat.value.style.height = "46px !important";
    }
  }
};

function closeForm() {
  isFormOpen.value = false;
}

watch(
  message,
  () => {
    resizeTextarea();
  },
  { deep: true, immediate: true }
);

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
</script>

<template>
  <Body
    :class="
      isFormOpen
        ? 'max-h-screen max-w-screen overflow-hidden bg-[#717171] !bg-opacity-20'
        : ''
    "
  ></Body>
  <button
    class="flex gap-3 items-center"
    @click="toggleForm"
    v-if="typeButton === 'primary' && !isFormOpen"
  >
    <a-icon-chat></a-icon-chat>
    <p>Чат-поддержка</p>
  </button>
  <button
    @click="toggleForm"
    v-else-if="typeButton === 'secondary' && !isFormOpen"
    class="z-40 fixed rounded bottom-10 focus:outline-none right-5 cursor-pointer bg-gray-50"
  >
    <a-icon-chat-cat class="w-35 h-35"></a-icon-chat-cat>
  </button>
  <button
    @click="toggleForm"
    v-else-if="typeButton === 'third' && !isFormOpen"
    class="cursor-pointer flex flex-row space-x-[10px] items-center"
  >
    <a-icon-chat-cat class="w-12 h-10"></a-icon-chat-cat>
    <p class="font-sans text-sm md:text-base text-[#6F6F6F]">
      Чат со службой поддержки
    </p>
  </button>
  <div
    ref="chat"
    v-if="isFormOpen"
    class="z-60 fixed flex left-0 right-0 top-[51px] bottom-0 bg-[#717171] !bg-opacity-20"
    style="z-index: 1000"
    tabindex="0"
  >
    <div
      class="ml-auto right-0 pt-0 md:pt-5 pb-0 md:pb-5 pr-0 md:pr-5 max-h-screen flex"
    >
      <form
        action="#"
        class="grid bg-white grid-rows-5 mt-auto md:max-h-190 h-full w-full md:w-118 bg-gray-50 px-3 md:rounded-xl lg:border border-[#6F6F6F] gap-5"
      >
        <div
          class="relative flex items-center flex-row row-end-1 gap-5 bg-gray-50 pt-5"
        >
          <div class="relative">
            <p class="text-xl block font-firasans font-medium">
              Чат со службой поддержки
            </p>
            <br />

            <!-- <b class="text-xs font-normal">{{ operatorsOnline(operators) }}</b> -->
          </div>

          <a-icon-close
            class="w-4 h-4 absolute block cursor-pointer top-6 right-2"
            @click="closeForm()"
          ></a-icon-close>

          <div class="ml-auto flex gap-8 items-center">
            <!-- <img
              v-if="messages.length"
              :src="download"
              class="w-6 cursor-pointer pt-1"
              @click="downloadChat"
            /> -->
            <!-- <button @click="toggleForm()" class="my-auto max-w-5 text-3xl">
              x
            </button> -->
          </div>
        </div>
        <div
          id="scrollArea"
          ref="scrollArea"
          class="h-full row-start-1 row-end-6 row-span-3 overflow-y-auto"
        >
          <div
            data-messages=""
            v-if="messages.length"
            class="flex flex-col mt-2 px-1 gap-5"
          >
            <template v-for="message in messages">
              <div v-if="message.msgBody !== 'exit'" class="relative">
                <div
                  class="w-3/4 min-w-3/4 font-sans italic text-sm max-w-3/4 p-2 pb-7 relative flex gap-5"
                  :class="[
                    message.isClientMsg
                      ? 'bg-primary float-right flex-row borderClient '
                      : 'bg-[#FCC900] justify-end float-left flex-row-reverse borderBank',
                  ]"
                >
                  <p>{{ message.msgBody }}</p>
                  <span class="absolute bottom-2 text-xs font-light right-2">
                    {{ formatDate(message.datetime, DEFAULT_HOURS_FORMAT) }}
                  </span>
                  <!-- <img
                    :src="message.isClientMsg ? userProfile : operatorProfile"
                    class="max-w-10 max-h-10"
                    alt="profile"
                  /> -->
                </div>
              </div>
            </template>
          </div>
          <template v-else>
            <div class="flex flex-col w-full gap-5">
              <div id="infoFrom">
                <!-- <img src="111111.png" class="defwidarea" alt=""> -->
                <div class="widprewiewtxt mt-5">
                  <b class="text-xl">Добро пожаловать в чат!</b>
                  <p class="mt-3 text-lg">
                    Ваш вопрос — наш быстрый и профессиональный ответ!
                  </p>
                </div>
              </div>
              <a-icon-chat-cat class="w-40 h-24 mx-auto"></a-icon-chat-cat>
              <!-- <img class="h-40" :src="review_chat" /> -->

              <div class="relative w-full mt-8">
                <select
                  v-model="theme"
                  class="w-full px-5 py-2 !bg-[#F9F9F9] font-sans text-[#6F6F6F] h-[48px] appearance-none border-[1px] cursor-pointer rounded-[10px] focus:outline-none"
                  size="1"
                  :class="
                    createTicketValidator.theme.$error
                      ? 'border-red'
                      : 'border-[#E1E1E1]'
                  "
                >
                  <option disabled selected :value="null">
                    Тема сообщения
                  </option>
                  <option v-for="theme in themes" :value="theme.id">
                    {{ theme.theme }}
                  </option>
                </select>
                <a-icon-arrow
                  class="absolute z-30 right-4 top-[0.7rem]"
                ></a-icon-arrow>
              </div>

              <input
                v-model="phone"
                class="w-full px-5 py-2 bg-white !bg-[#F9F9F9] font-sans text-[#6F6F6F] h-[48px] appearance-none border-[1px] cursor-pointer rounded-[10px] focus:outline-none"
                v-mask="'+# (###) ###-##-##'"
                maxlength="18"
                placeholder="Ваш телефон"
                type="text"
                :class="
                  createTicketValidator.phone.$error
                    ? 'border-red'
                    : 'border-[#E1E1E1]'
                "
              />
            </div>
          </template>
        </div>

        <div class="bg-gray-50 rounded-none md:rounded-b-x pb-5 row-end">
          <div class="relative">
            <textarea
              placeholder="Ваше сообщение"
              name="comment"
              ref="textareaChat"
              class="w-full overflow-y-auto !min-h-[46px] !max-h-[102px] font-sans focus:outline-none placeholder:italic text-[#9D9D9D] rounded-[10px] px-5 !pr-14 py-3"
              style="box-shadow: 0px 0px 4px 0px #00000040 inset; resize: none"
              v-model="message"
              @keydown.enter="sendMessage"
              @input="resizeTextarea"
              :class="
                createMessageValidator.message.$error
                  ? 'border-1 border-red'
                  : 'border-0'
              "
            />
            <button
              type="submit"
              form="widform"
              @click="sendMessage"
              id="outmessageB"
              class="max-w-10 absolute !top-0 !bottom-0 pb-1.5 max-h-10 right-0 my-auto"
              style="-webkit-appearance: none"
            >
              <!-- <img class="min-w-7 min-h-7 mr-2" src="@/assets/svgs/paperplane.svg" alt="->" /> -->
              <a-icon-paperline class="min-w-7 min-h-7 mr-2"></a-icon-paperline>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.borderBank {
  border-radius: 10px 10px 10px 0px;
  box-shadow: 0px 0px 4px 0px #00000040;
}
.borderClient {
  border-radius: 10px 10px 0px 10px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

::-webkit-scrollbar {
  width: 0px;
}

.imgwid {
  width: 70px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#widbutton {
  border-radius: 4px;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  border: 2px solid #ffc107;
  width: 70px;
  background-color: white;
}

#widform {
  height: 760px;
  background-color: #f1f1f1;
  padding: 10px 10px 0px;
  border-radius: 20px;
  right: 20px;
  bottom: 20px;
  width: 470px;
  position: absolute;
}

#widClientTheme {
  background-color: white;
  padding-left: 6px;
  height: 36px;
  border: 0px solid silver;
  width: 470px;
  margin-bottom: 10px;
  margin-top: 28px;
  border-radius: 16px;
}

#widchop {
  margin-top: 14px;
}

#widchop input {
  width: 100%;
  display: block;
}

.imgclose {
  margin: 6px 6px 0px 0px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  float: right;
}

.imgavatar {
  float: left;
  height: 48px;
  width: 48px;
  border-radius: 20px;
}

#outmessageB {
  cursor: pointer;
  /* margin-left: 8px;
  margin-top: 14px; */
  border: 0px;
}

#widDialog div {
  margin-top: 10px;
}

#widDialog {
  overflow-x: hidden;
  height: 630px;
  width: 100%;
  overflow-y: scroll;
  padding: 0px 6px 0px 6px;
  border-radius: 20px;
}

#widClientPhone {
  padding-left: 10px;
  height: 36px;
  border: 0px solid silver;
  border-radius: 16px;
  width: 460px;
  margin-top: 0px;
}

.defwidarea {
  display: inline;
  height: 320px;
  margin-top: 54px;
}

#widworkarea {
  min-height: 610px;
}

.widheadtxt b:nth-child(1) {
  font-weight: 500;
  display: block;
  font-size: 22px;
}

.widleftdialog b {
  font-weight: 400;
}

.widdialogtxt {
  font-weight: 500;
  margin: 0px;
}

.widleftdialog {
  background-color: white;
  text-align: left;
  border-radius: 0px 14px 14px 14px;
  width: 290px;
  display: block;
  margin-left: 40px;
  padding: 8px;
}

.widrightdialog p {
  margin: 0px;
}

.widrightdialog {
  float: left;
  background-color: #fedf2c;
  text-align: left;
  border-radius: 14px 0px 14px 14px;
  width: 290px;
  display: block;
  padding: 8px;
  margin-left: 94px;
}

#widstatusoper {
  font-size: 12px;
  font-weight: normal;
}

.widheadtxt {
  padding-top: 10px;
  line-height: 10px;
  margin-left: 20px;
  float: left;
}

.widblocmess {
  display: inline-block;
}

.widdialogdate {
  text-align: center;
}

.widtimedialog {
  text-align: right;
  font-weight: 300;
  margin: 0px;
}

.widprewiewtxt {
  display: block;
  text-align: center;
}

#infoFrom {
  text-align: center;
}

.widimgadm {
  margin-top: 8px;
  float: left;
  height: 30px;
  width: 30px;
}

.widimguser {
  margin-top: 8px;
  margin-left: 8px;
  height: 30px;
  width: 30px;
}

#outmessageB img {
  height: 26px;
  width: 32px;
}

@media screen and (max-width: 570px) {
  #widform {
    width: 390px;
    height: 640px;
    right: auto;
    bottom: auto;
  }

  .defwidarea {
    height: 160px;
  }

  #widworkarea {
    min-height: 510px;
  }

  .widrightdialog {
    float: left;
    background-color: #fedf2c;
    text-align: left;
    border-radius: 14px 0px 14px 14px;
    width: 290px;
    display: block;
    padding: 8px;
    margin-left: 0px;
  }

  .widimguser {
    margin-top: 8px;
    margin-left: 8px;
    height: 30px;
    width: 30px;
  }

  #outmessageB img {
    height: 26px;
    width: 32px;
  }

  #widDialog {
    height: 510px;
  }
}
</style>
