import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import timezone from 'dayjs/plugin/timezone.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Moscow')

import {
  monthNamesShort,
  monthNames,
  weekDayNames
} from '@/utils/constants'

export function useDate() {
  function getDate(date) {
    return dayjs(date).tz('Europe/Moscow').get('date')
  }

  function getTime(date) {
    return `${dayjs(date).tz('Europe/Moscow').format('HH:mm')}`
  }

  function getDayOfWeek(date) {
    return weekDayNames[dayjs(date).tz('Europe/Moscow').day()]
  }

  function getMonth(date) {
    return monthNames[dayjs(date).tz('Europe/Moscow').get('month')]
  }

  function getMonthNumber(date) {
    return dayjs(date).tz('Europe/Moscow').format('MM')
  }

  function getShortMonth(date) {
    return monthNamesShort[dayjs(date).tz('Europe/Moscow').get('month')]
  }

  function getYear(date) {
    return dayjs(date).tz('Europe/Moscow').get('year')
  }

  function formatDate(date, string) {
    return dayjs(date).tz('Europe/Moscow').format(string)
  }

  return {
    getDate,
    getTime,
    getDayOfWeek,
    getMonth,
    getMonthNumber,
    getShortMonth,
    getYear,
    formatDate
  }
}
