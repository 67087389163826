<template>
  <svg
    width="108"
    height="88"
    viewBox="0 0 108 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.7022 13.6772C33.7022 13.6772 57.9232 3.54889 83.4626 22.4709C109.002 41.393 93.7293 70.7141 86.6216 76.5744C79.5139 82.4412 70.0624 90.7385 43.4722 85.1425C16.8819 79.5465 9.48125 64.8473 9.48125 49.3873C9.48125 33.9273 15.7992 20.6013 33.7022 13.6707V13.6772Z"
      fill="#FFED00"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.721 53.7C21.6954 55.6018 14.5547 59.6119 11.5099 62.1198C10.6372 59.6764 10.083 57.1362 9.77724 54.551C14.8541 53.5066 23.6892 51.9141 22.721 53.7Z"
      fill="#263238"
    />
    <path
      d="M24.9637 65.0468C25.607 66.8326 20.4155 70.1206 17.5554 72.1836C15.3514 69.7724 13.6761 67.1614 12.4403 64.4085C16.4343 64.4085 24.2821 63.1449 24.9637 65.0468Z"
      fill="#263238"
    />
    <path
      d="M27.2038 74.3564C27.121 75.2525 26.0891 76.6708 24.8024 78.1214C22.6812 76.7998 20.8211 75.3685 19.1968 73.8406C22.6238 73.4151 27.3503 72.7253 27.2038 74.3564Z"
      fill="#263238"
    />
    <path
      d="M79.5308 81.8672C77.3777 83.1824 74.8998 84.3751 71.9314 85.2905C72.3072 83.6981 72.7595 81.8156 73.1736 80.2619C73.7596 78.0505 77.0847 80.249 79.5308 81.8672Z"
      fill="#263238"
    />
    <path
      d="M88.0757 75.175C87.5661 75.7358 87.0756 76.2065 86.6233 76.5804C85.2092 77.7473 83.7059 79.0045 81.9924 80.2294C81.5465 77.4959 80.7375 73.5761 81.0241 71.4034C81.349 68.9149 85.6233 73.2022 88.0757 75.1685V75.175Z"
      fill="#263238"
    />
    <path
      d="M89.3265 73.6666C88.3519 70.7719 86.1607 66.0462 85.5046 63.2289C84.8039 60.2117 90.5559 65.2468 93.0912 67.5871C91.8936 69.9789 90.5814 72.042 89.3265 73.6666Z"
      fill="#263238"
    />
    <path
      d="M82.6701 24.076C82.6701 24.076 96.0979 13.6833 97.6777 19.015C99.2574 24.3467 92.4097 35.5388 92.4097 35.5388"
      fill="#FFED00"
    />
    <path
      d="M82.6701 24.076C82.6701 24.076 96.0979 13.6833 97.6777 19.015C99.2574 24.3467 92.4097 35.5388 92.4097 35.5388"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.4916 15.5467C34.4916 15.5467 24.4844 5.41841 20.535 8.08103C16.5856 10.7437 20.2738 24.8691 20.2738 24.8691"
      fill="#FFED00"
    />
    <path
      d="M34.4916 15.5467C34.4916 15.5467 24.4844 5.41841 20.535 8.08103C16.5856 10.7437 20.2738 24.8691 20.2738 24.8691"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.424 33.6563C91.424 33.6563 97.5837 23.6119 96.2142 20.6656C94.8446 17.7193 84.4043 26.8999 84.4043 26.8999"
      fill="#FFED00"
    />
    <path
      d="M91.424 33.6563C91.424 33.6563 97.5837 23.6119 96.2142 20.6656C94.8446 17.7193 84.4043 26.8999 84.4043 26.8999"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9005 16.6876C32.9005 16.6876 23.3201 9.41534 20.753 9.75704C18.1859 10.1052 21.6066 23.4376 21.6066 23.4376"
      fill="#FFED00"
    />
    <path
      d="M32.9005 16.6876C32.9005 16.6876 23.3201 9.41534 20.753 9.75704C18.1859 10.1052 21.6066 23.4376 21.6066 23.4376"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.2332 39.91C54.4564 40.1873 59.4249 41.3219 59.4186 42.1472C59.4186 43.5333 54.4564 46.6472 52.5709 45.6092C50.6917 44.5712 47.959 40.5934 48.6342 40.0648C48.94 39.8262 49.9465 39.8004 51.2268 39.91H51.2332Z"
      fill="#263238"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M73.2924 34.5623C69.9386 33.7953 66.745 34.3081 64.8141 35.7048C66.0332 33.127 69.6158 31.7642 73.3795 32.6249C76.7209 33.3891 79.1819 35.671 79.679 38.1718C78.3171 36.5445 76.0375 35.19 73.2924 34.5623Z"
      fill="#263238"
    />
    <path
      d="M36.8702 29.7926C40.3088 29.5844 43.3764 30.6091 45.0647 32.3041C44.2706 29.5615 40.9472 27.638 37.0918 27.8718C33.6733 28.0809 30.8801 29.9278 29.9897 32.3153C31.5915 30.9374 34.0584 29.9686 36.8702 29.7926Z"
      fill="#263238"
    />
    <path
      d="M74.5398 47.3691C74.5398 47.3691 82.8972 45.1706 88.5856 49.4644"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M86.3687 58.4386C80.9924 51.3791 73.0873 51.205 73.0873 51.205"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.7956 53.6678C71.7956 53.6678 77.0445 56.1435 79.4778 62.3004"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.9886 39.2847C31.9886 39.2847 24.9626 34.198 18.1276 36.1514"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.9838 43.3976C31.9838 43.3976 24.5437 40.6899 17.0271 45.3382"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.321 46.1634C32.321 46.1634 26.5435 46.5695 22.0909 51.4371"
      stroke="#263238"
      stroke-width="0.605839"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.9614 51.8127C46.3391 55.5807 47.8807 60.0673 50.4422 60.4904C53.0038 60.9135 55.595 57.1477 56.2173 53.3797C54.7128 52.2097 52.4928 50.437 52.644 47.0488C50.5551 50.4965 50.9938 50.4756 46.9614 51.8127Z"
      fill="#333D42"
    />
    <path
      d="M47.746 58.0871C47.6032 58.9661 49.3945 60.3324 50.4608 60.5056C51.6398 60.7146 53.039 59.8247 53.6241 59.0034C53.9391 57.9401 52.0441 57.4955 50.9779 57.3223C49.9116 57.1491 47.913 57.0598 47.746 58.0871Z"
      fill="#FF6E6E"
    />
    <path
      d="M75.5217 22.6965C75.5217 22.6965 61.0197 11.627 41.2888 16.6815C41.2888 16.6815 63.873 17.6421 75.5217 22.6965Z"
      fill="#263238"
    />
    <path
      d="M71.0024 26.5519C71.0024 26.5519 60.7803 18.6091 43.8963 21.9809C43.8963 21.9809 63.1559 23.425 71.0024 26.5519Z"
      fill="#263238"
    />
    <path
      d="M51.5067 25.108C51.5067 25.108 61.9662 26.0686 61.9662 27.2742C61.9662 28.4798 57.6856 29.4404 56.023 28.7184C54.3605 27.9963 51.5067 25.108 51.5067 25.108Z"
      fill="#263238"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.6927 19.8143C82.9079 11.4846 71.6773 5.97133 59.4947 4.40013C47.3121 2.82893 35.0502 5.31237 25.4067 11.3943C15.7504 17.4842 9.47143 26.7207 8.12871 37.1317L12.3605 37.6775C13.5213 28.6765 18.969 20.4988 27.6828 15.0033C36.4093 9.49971 47.656 7.17542 58.9489 8.63189C70.2419 10.0883 80.5308 15.1901 87.5754 22.7277C94.6097 30.2543 97.8043 39.5466 96.6434 48.5475L100.875 49.0933C102.218 38.6823 98.4878 28.1549 90.6927 19.8143Z"
      fill="#3E484D"
    />
    <path
      d="M75.725 12.177C62.6746 6.67014 54.8834 5.43642 39.8838 7.91015C54.4101 4.07711 62.31 4.40932 75.725 12.177Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.5911 58.0243L9.14223 50.4699L7.03272 50.1515L9.07068 59.5208L44.2398 60.9454L44.4945 59.2578L10.5911 58.0243Z"
      fill="white"
      stroke="#3E484D"
      stroke-width="0.605839"
    />
    <rect
      x="-0.327649"
      y="0.256885"
      width="4.80433"
      height="24.9288"
      rx="2.40216"
      transform="matrix(-0.989036 -0.147674 -0.0925998 0.995703 101.606 38.1027)"
      fill="white"
      stroke="#3E484D"
      stroke-width="0.605839"
    />
    <rect
      x="0.247978"
      y="0.334441"
      width="4.80433"
      height="24.9288"
      rx="2.40216"
      transform="matrix(0.992852 0.119351 -0.174226 0.984706 9.93455 26.2668)"
      fill="white"
      stroke="#3E484D"
      stroke-width="0.605839"
    />
    <path
      d="M106.921 45.5188C107.13 43.2724 105.49 41.1813 103.259 40.8481C102.478 40.7315 101.786 41.2743 101.713 42.0606L100.012 60.3515C99.9385 61.1377 100.512 61.8696 101.293 61.9862C103.525 62.3194 105.503 60.7684 105.712 58.522L106.921 45.5188Z"
      fill="#3E484D"
    />
    <path
      d="M3.40426 32.1569C3.79732 29.9353 5.9318 28.3527 8.17174 28.6219C8.95572 28.7162 9.47974 29.4229 9.34217 30.2004L6.14167 48.2893C6.0041 49.0669 5.25703 49.6208 4.47305 49.5265C2.23311 49.2573 0.73592 47.2381 1.12899 45.0165L3.40426 32.1569Z"
      fill="#3E484D"
    />
    <rect
      width="9.38698"
      height="4.26681"
      rx="2.1334"
      transform="matrix(-0.999626 -0.0273441 -0.0273441 0.999626 50.9775 58.0884)"
      fill="#3E484D"
    />
    <path
      d="M52.8252 45.8811C52.1082 50.6114 55.5939 54.5497 60.0666 54.0563"
      stroke="#333D42"
      stroke-width="0.605839"
    />
    <path
      d="M52.8175 45.9004C51.9054 50.597 47.1288 53.2008 43.0812 51.235"
      stroke="#333D42"
      stroke-width="0.605839"
    />
    <ellipse
      cx="5.09935"
      cy="7.37713"
      rx="5.09935"
      ry="7.37713"
      transform="matrix(-0.992454 -0.122621 -0.122621 0.992454 75.4963 35.5425)"
      fill="#333D42"
    />
    <ellipse
      cx="5.09935"
      cy="7.37713"
      rx="5.09935"
      ry="7.37713"
      transform="matrix(0.998903 0.0468372 0.0468372 -0.998903 32.2727 45.0009)"
      fill="#333D42"
    />
    <ellipse
      cx="4.28854"
      cy="6.20414"
      rx="4.28854"
      ry="6.20414"
      transform="matrix(-0.992454 -0.122621 -0.122621 0.992454 74.5497 36.5942)"
      fill="white"
    />
    <ellipse
      cx="4.28854"
      cy="6.20414"
      rx="4.28854"
      ry="6.20414"
      transform="matrix(0.998903 0.0468372 0.0468372 -0.998903 33.137 43.88)"
      fill="white"
    />
    <ellipse
      cx="3.78652"
      cy="5.47789"
      rx="3.78652"
      ry="5.47789"
      transform="matrix(-0.992454 -0.122621 -0.122621 0.992454 73.179 37.0565)"
      fill="#333D42"
    />
    <ellipse
      cx="3.60426"
      cy="5.12993"
      rx="3.60426"
      ry="5.12993"
      transform="matrix(0.998903 0.0468372 0.0468372 -0.998903 34.7036 42.7516)"
      fill="#333D42"
    />
    <ellipse
      cx="0.659188"
      cy="0.674189"
      rx="0.659188"
      ry="0.674189"
      transform="matrix(-1 0 0 1 69.2419 38.6568)"
      fill="white"
    />
    <ellipse
      cx="0.659188"
      cy="0.674189"
      rx="0.659188"
      ry="0.674189"
      transform="matrix(-1 0 0 1 38.2771 34.6218)"
      fill="white"
    />
  </svg>
</template>
